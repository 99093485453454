import React from 'react';

const WIDTH_DEFAULT = '300px';

const Modal = ({
  width = WIDTH_DEFAULT,
  show,
  close,
  children,
}) => (
  <div className="container-modal" style={{ display: show ? 'flex' : 'none' }}>
    <div className="click-layer" onClick={close} />
    <div className="modal-content-modal">
      {children}
    </div>
  </div>
);

export default Modal;
