import React from 'react';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';

const TabsTwo = (props) => {
  const tab3 = 'Experience';
  const tab4 = 'Education & Certification';
  const { tabStyle } = props;
  return (
    <div>
      {/* Start Tabs Area */}
      <div className="tabs-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Tabs>
                <TabList className={`${tabStyle}`}>
                  <Tab>{tab3}</Tab>
                  <Tab>{tab4}</Tab>
                </TabList>

                {/*<TabPanel>*/}
                {/*  <div className="single-tab-content">*/}
                {/*    <ul>*/}
                {/*      <li>*/}
                {/*        <a href="#">*/}
                {/*          User experience design*/}
                {/*          <span> - UI/UX</span>*/}
                {/*        </a>*/}
                {/*        Delight the user and make it work.*/}
                {/*      </li>*/}
                {/*      <li>*/}
                {/*        <a href="#">*/}
                {/*          Web and UI*/}
                {/*          <span> - Development</span>*/}
                {/*        </a>*/}
                {/*      </li>*/}
                {/*      <li>*/}
                {/*        <a href="#">*/}
                {/*          Develop*/}
                {/*          <span> - Coding</span>*/}
                {/*        </a>*/}
                {/*          Design and build core backend and front end*/}
                {/*      </li>*/}
                {/*    </ul>*/}
                {/*  </div>*/}
                {/*</TabPanel>*/}

                <TabPanel>
                  <div className="single-tab-content">
                    <ul>
                      <li>
                        <a href="#">
                          Sr. Software Engineer
                          <span> - Xop Networks</span>
                        </a>
                        {' '}
                        2019 - Current
                      </li>
                      <li>
                        <a href="#">
                          Sr. Front-end Engineer
                          <span> - Emergn </span>
                        </a>
                        {' '}
                        2018 - 2019
                      </li>
                      <li>
                        <a href="#">
                          Full Stack Engineer
                          <span> - Lursoft </span>
                        </a>
                        {' '}
                        2017 - 2018
                      </li>
                        <li>
                            <a href="#">
                                Front End Supervisor
                                <span> - Hello IT </span>
                            </a>
                            {' '}
                            2017 - 2018
                        </li>
                        <li>
                            <a href="#">
                                Freelance Frontend Engineer
                                <span> - VivaWeb inc. </span>
                            </a>
                            {' '}
                            2016 - 2017
                        </li>
                    </ul>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="single-tab-content">
                    <ul>
                      <li>
                        <a href="#">
                          Automation and Computer Engineering
                          <span> - Riga Technical University</span>
                        </a>
                        {' '}
                        2017 - Present
                      </li>
                      <li>
                        <a href="#">
                          Programmer
                          <span> - SIA Progmeistars, Riga, Latvia</span>
                        </a>
                        {' '}
                        2008
                      </li>
                    </ul>
                  </div>
                </TabPanel>

              </Tabs>
            </div>
          </div>
        </div>
      </div>
      {/* End Tabs Area */}
    </div>
  );
};


export default TabsTwo;
