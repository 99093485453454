import React, { Component } from 'react';

class ContactThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rnName: '',
      rnEmail: '',
      rnSubject: '',
      rnMessage: '',
      status: '',
    };
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: 'SUCCESS' });
      } else {
        this.setState({ status: 'ERROR' });
      }
    };
    xhr.send(data);
  }

  canBeSubmitted() {
    const {
      rnName, rnEmail, rnSubject, rnMessage,
    } = this.state;
    return !!(rnName && rnEmail && rnSubject && rnMessage);
  }

  render() {
    const { status } = this.state;
    const isEnabled = this.canBeSubmitted();
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">{this.props.contactTitle}</h2>
                <p className="description">
                  I am available for work. Connect with me via email:
                  <a href="mailto:vkuprin@inbox.lv"> vkuprin@inbox.lv</a>
                </p>
              </div>
              <div className="form-wrapper">
                <form
                  onSubmit={this.submitForm}
                  action="https://formspree.io/xqkqrzga"
                  method="POST"
                >
                  <label htmlFor="item01">
                    <input
                      required
                      type="text"
                      name="name"
                      id="item01"
                      value={this.state.rnName}
                      onChange={(e) => { this.setState({ rnName: e.target.value }); }}
                      placeholder="Your Name *"
                    />
                  </label>

                  <label htmlFor="item02">
                    <input
                      required
                      type="text"
                      name="email"
                      id="item02"
                      value={this.state.rnEmail}
                      onChange={(e) => { this.setState({ rnEmail: e.target.value }); }}
                      placeholder="Your Email *"
                    />
                  </label>

                  <label htmlFor="item03">
                    <input
                      type="text"
                      name="subject"
                      id="item03"
                      value={this.state.rnSubject}
                      onChange={(e) => { this.setState({ rnSubject: e.target.value }); }}
                      placeholder="Write a Subject"
                    />
                  </label>
                  <label htmlFor="item04">
                    <textarea
                      required
                      type="text"
                      id="item04"
                      name="message"
                      value={this.state.rnMessage}
                      onChange={(e) => { this.setState({ rnMessage: e.target.value }); }}
                      placeholder="Your Message"
                    />
                  </label>
                  {status === 'SUCCESS' ? <p className="text-white">Thanks!</p> : <button disabled={!isEnabled} className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>}
                  {status === 'ERROR' && <p className="text-white">Ooops! There was an error.</p>}

                </form>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img src={`${this.props.contactImages}`} alt="trydo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactThree;
