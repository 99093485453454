import {
  FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter,
} from 'react-icons/fa';
import { FiMenu, FiX } from 'react-icons/fi';
import React, { Component } from 'react';
import Modal from '../modal/Modal'


import Scrollspy from 'react-scrollspy';

const SocialShare = [
  { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
  { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
  { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
  { Social: <FaTwitter />, link: 'https://twitter.com/' },
];
class HeaderThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener('load', () => {
      console.log('All assets are loaded');
    });
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  closeModal = () => {
    this.setState({
      showModal: false
    })
  }

  menuTrigger() {
    document.querySelector('.header-wrapper').classList.toggle('menu-open');
  }

  CLoseMenuTrigger() {
    document.querySelector('.header-wrapper').classList.remove('menu-open');
  }

  stickyHeader() {}

  render() {
    window.addEventListener('scroll', () => {
      const value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add('sticky');
      } else {
        document.querySelector('.header--fixed').classList.remove('sticky');
      }
    });

    const elements = document.querySelectorAll('.has-droupdown > a');
    for (const i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement.querySelector('.submenu').classList.toggle('active');
          this.classList.toggle('open');
        };
      }
    }
    const { logo, color = 'default-color' } = this.props;
    let logoUrl;
    if (logo === 'light') {
      logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />;
    } else if (logo === 'dark') {
      logoUrl = <img src="/assets/images/logo/logo_v.png" alt="Digital Agency" />;
    } else if (logo === 'symbol-dark') {
      logoUrl = null;
    } else if (logo === 'symbol-light') {
      logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
    } else {
      logoUrl = <img src="/assets/images/logo/logo.png" alt="Digital Agency" />;
    }
    return (
      <header className={`header-area header-style-two header--fixed ${color}`}>
        <div className="header-wrapper">
          <div className="header-left d-flex align-items-center">
            <div className="logo">
              <a href={this.props.homeLink}>
                {logoUrl}
              </a>
            </div>
            <nav className="mainmenunav d-lg-block ml--50">
              <Scrollspy className="mainmenu" items={['home', 'about', 'skills', 'portfolio', 'contact']} currentClassName="is-current" offset={-200}>
                <li><a href="#home">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#skills">Skills</a></li>
                <li><a href="#portfolio">Portfolio</a></li>
                {/* <li><a href="#blog">Blog</a></li> */}
                <li><a href="#contact">Contact</a></li>
              </Scrollspy>
            </nav>
          </div>
          <div className="header-right">
            {/* <div className="social-share-inner">
                            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div> */}
            <div onClick={this.toggleModal}  className="header-btn">
              <a className="rn-btn" href="#">
                <span>Log in</span>
              </a>
            </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span onClick={this.menuTrigger} className="menutrigger text-white-bg"><FiMenu /></span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
            </div>
          </div>
        </div>
        <Modal show={this.state.showModal} close={this.closeModal}>
          <div className="form-login form-wrapper contact-form--1">
            <form>
              <input
                  type='text'
                  name='username'
                  placeholder="Username"
              />
              <input
                  type='password'
                  name='password'
                  placeholder="Password"
              />
              <button className="rn-button-style--2">Sign In</button>
            </form>
          </div>
        </Modal>
      </header>
    );
  }
}
export default HeaderThree;
